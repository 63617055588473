import React, { useEffect } from 'react';
import { Link, graphql } from "gatsby"
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import {Helmet} from "react-helmet"
import Seo from "../components/seo"

  

const Agenda = ({ data, location }) => {
    var agenda=data.fires.edges
    console.log(agenda);
    const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} tigitle={siteTitle}>
    <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js" integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V" crossorigin="anonymous"></script>
    </Helmet>
    <Seo title="Fires, festes, mercats, l'agenda cap de setmana, restauració i allotjament a Catalunya" description="Tota l'agenda festiva de Catalunya: fires, festes, mercats, hotels, festes majors, càmpings, restaurants, albergs, mercats setmanals ... a Barcelona, Tarragona, Lleida i Girona" />
    <article
      className="global-wrapper blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >
    <h1 className='mb-4' itemProp="headline">Fires, festes, mercats, l'agenda cap de setmana, restauració i allotjament a Catalunya</h1>
        <section>
        {agenda.map(node => (
          <div className="mb-4" key={node.artist.id}>
            <div className="card">
              <div className="row">
                <div className="col-xs-12 col-md-4">
                <img class="card-img-top" src={node.artist.img !== "" ? node.artist.img : `/imgs/poblacions/${node.artist.municipioslug}.jpg`} alt={node.artist.altimg} />
                </div>
                <div class="col-xs-12 col-md-8 card-body">
                  <Link to={`/esdeveniment/${node.artist.slug}/`}>
                  <h3 class="card-title">{node.artist.title}</h3>
                  </Link>
                  <p>
                    {(() => {
                      const fechaInicio = new Date(parseInt(node.artist.finicio));

                      if (!isNaN(fechaInicio.getTime())) {
                        const dia = fechaInicio.getDate().toString().padStart(2, '0');
                        const mes = (fechaInicio.getMonth() + 1).toString().padStart(2, '0');
                        const año = fechaInicio.getFullYear();

                        return `${dia}/${mes}/${año}`;
                      } else {
                        return '';
                      }
                      })()}&nbsp;-&nbsp;{(() => {
                      const fechaInicio = new Date(parseInt(node.artist.ffin));

                      if (!isNaN(fechaInicio.getTime())) {
                        const dia = fechaInicio.getDate().toString().padStart(2, '0');
                        const mes = (fechaInicio.getMonth() + 1).toString().padStart(2, '0');
                        const año = fechaInicio.getFullYear();

                        return `${dia}/${mes}/${año}`;
                      } else {
                        return '';
                      }
                    })()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        </section>
    </article>
    </Layout>
  )
}

export default Agenda

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    fires: allMysqlFires(
      limit: 50
      filter: {finished: {eq: 0}}
      sort: {order: ASC, fields: ffin}
    ) {
      edges {
        artist: node {
          id
          slug
          title
          altimg
          img
          municipioslug
          finicio
          ffin
        }
      }
    }
  }
`
